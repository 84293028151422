import React from 'react';
import './Register.scss';

class Register extends React.Component {

  render() {
    return (
      <div>
        
      </div>
    );
  }
}

export default Register;
